import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img, { FluidObject, FixedObject } from 'gatsby-image';
interface FeedContainerProps {
    
}

/**
 * Container for Instagram feed 
 * that will fetch data and pass props to visual component
 * Container -> Visual Component pattern. (ie: <Feed Container/> renders the <Feed/>)
 * @param props FeedContainerProps
 */
const FeedContainer = (props : FeedContainerProps) => {
    const data = useStaticQuery(graphql`
    {
    allInstaNode {
        edges {
            node {
            caption
            likes
            timestamp
            localFile {
                childImageSharp{
                    fluid(maxWidth:600){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            }
        }
    }
    }
    `);

    let imageData : InstagramPicture[] = [];
    // sort the data from most recent -> oldest
    data.allInstaNode.edges.sort((a : any, b : any) => b.node.timestamp - a.node.timestamp);

    // get all picture data and add to feed prop
    data.allInstaNode.edges.forEach((e : any) => {
        imageData.push({
            fluid: e.node.localFile.childImageSharp.fluid, 
            caption:e.node.caption, 
            likes:e.node.likes
        });
    });

    return (
        <>
            <Feed imageData={imageData}/>
        </>
    );
}

type InstagramPicture = {
    // gatsby image object
    fluid : FluidObject;
    caption : string;
    likes : number;
}

interface FeedProps {
    imageData : Array<InstagramPicture>;
}

/**
 * TODO make into a material UI gridlist
 * Visual layout of our Instagram feed 
 * @param props FeedProps
 */
const Feed = (props : FeedProps) => {
    return(
        <>
            {props.imageData.map((e, i) => {
                return(
                <div key={`insta${i}`} style={{maxWidth:'300px', textAlign:'center', margin:'auto'}}>
                    <Img fluid={e.fluid}/>
                    <p>{e.caption}</p>
                    <p>{`${e.likes} 💗`}</p>
                </div>
                );
            })}
        </>
    );
}


export default FeedContainer;
