import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import FeedContainer from '../components/instagram/FeedContainer';

const FeedPage = () => {
    return(<>
        <Layout>
            <SEO title={"Feed"}/>
            <FeedContainer/>
        </Layout>
    </>);
}

export default FeedPage;